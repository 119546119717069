/* page-title */
.page_header_bg{
    // background: url(../img/page/page-bg.jpg);
    background-repeat: no-repeat;
}
 .breadcrumb-bg {
     background-size: cover;
     background-position: center center;
}
 .small-text {
     color: #647589;
     font-size: 18px;
     font-weight: 500;
}
 .page-title h1 {
     font-size: 60px;
}
 .page-height {
     min-height: 500px;
}
 .page-breadcumb nav ol {
     background: 0;
     padding: 0;
     margin: 0;
}
 .page-breadcumb .breadcrumb-item a {
     color: #95a3b3;
     font-weight: 500;
}
 .page-breadcumb .breadcrumb-item.active {
     font-weight: 500;
     color: #e12454;
}
 .page-breadcumb .breadcrumb-item + .breadcrumb-item::before {
     content: "|";
}