
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.header-menu-area{
  background-image: url('');
}


.btn-send-from{
  border: none;
  padding: 17px 60px;
  background-color: white;
}

.div-btn-head{
  display: flex;
  justify-content: center;
}

.head-pro3 {
  background: #13232f;
  box-shadow: 0 5px 15px rgba(0,0,0,.35);
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 15px;
  width: 300px;

}

.pro-imggd>img {

  height: 300px;
  object-fit: cover;
  width: 377px;
}
.head-di1 {
  /* border: 1px solid #ada4a4; */
  display: flex;
  justify-content: space-between;
  /* padding: 5px 10px; */
}

.pro-span1{
  font-size: 15px;
  font-weight: bold;

  color: #FDCD07;
}
.pro-span2{
  font-size: 15px;
  padding: 20px 0px 0px 0px;
  color: white;
}


.head-inject-d2{
    
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}


.head-inject-d2{
  
  display: flex;
  justify-content: center;
  width: 100%;
}
.head-inject-d1{
  
width: 80%;
}

.services-d1{
  display: flex;
  justify-content: center;
  width: 100%;

}
.services-d2{
width: 80%;
  
}

.services-d2>p{
font-size: 17px;
}

.slick-slide img {
  display: block;
  height: 700px;
  object-fit: cover;
}


@media (max-width:800px) {
  .slick-slide img {
    display: block;
    height:  unset !important;
    object-fit: cover;
  }
  
}

.head-inject-d3{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.head-di1>span{
color: white;
}